import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import UserAds from './UserAds/UserAds'




const ThirdPartyRoutes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/userad/:companyName" element={<UserAds />} />
            </Routes>
        </Suspense>
    );
}

export default ThirdPartyRoutes;