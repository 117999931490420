import React, { useEffect, useState, useContext } from "react";
import '../AdminDashboard/AdminDashboard.css'
import { useNavigate } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import { URLS } from "../../../utilities/constants";
import axios from "axios";
import { DataContext } from '../../../utilities/DataContext';
import AlertComponent from "../../../Components/AlertComponent";

const AdminDashBoard = () => {
    const { setBusinessForm1data, setBusinessForm2data, token, setIsLoggedIn, setToken, setUserData, setCompanyInfo, setUserType, userData, setChatData, setMessageCount, setTab, setNoOfBedErr, setAddDetails } = useContext(DataContext);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [instaList, setInstaList] = useState([])
    const [responseErr, setResponseErr] = useState('')

    const gotoInstaList = () => {
        navigate('/admin/instaposts')
    }

    const gotoLogin = () => {
        setResponseErr('')
        setUserData({})
        setCompanyInfo({})
        setUserType('')
        setTab('')
        setNoOfBedErr('')
        setMessageCount('')
        setToken('')
        setChatData([])
        setBusinessForm1data({})
        setBusinessForm2data({})
        setIsLoggedIn(false)
        setAddDetails({})
    }

    useEffect(() => {
        document.title = 'Admin Dashboard'
        getInstaPosts()
    }, [])

    const getInstaPosts = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.INSTA_POSTS}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                console.log('response', response)
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response?.data.data
                    setInstaList(data)
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                if (error.response && error.response.data.message === 'Ungültiges Token. Bitte melden Sie sich erneut an.') {
                    setResponseErr(error.response.data.message);
                }
            });
    }

    return (
        <>
            <AdminHeader></AdminHeader>
            <div style={{ padding: '5vmin 0 0 3vmin', position: 'absolute', top: 80 }}>
                <span className="adminheadertext">Administraton Dashboard</span>
                <div className="div_grid" onClick={gotoInstaList}>
                    <span className="instatext">{instaList.length}</span>
                    <span className="instatextheader">Insta Posts</span>
                </div>
                {responseErr !== '' && <AlertComponent message={responseErr} onClose={() => { gotoLogin() }} />}
            </div>
        </>

    )
}
export default AdminDashBoard;

