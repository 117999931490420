// Header.js
import React, { useState, useContext, useEffect } from 'react';
import { Navbar, Nav, Button, Modal } from 'react-bootstrap';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import '../../app.css'
import LoginForm from '../../screens/Login/LoginForm';
import { DataContext } from '../../utilities/DataContext';
import { Strings, URLS } from '../../utilities/constants';
import axios from 'axios';
import './Header.css'
import { ClipLoader } from 'react-spinners';
import AlertComponent from "../../Components/AlertComponent";

const Header = ({ loginClicked, isClose, isFrom }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#F0AA89'
        }
    };
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const { setUserData, token, setToken, isLoggedIn, setIsLoggedIn, setBusinessForm1data, setBusinessForm2data, setAddDetails, userType, userData, messageCount, setMessageCount, chatData, setChatData, setUserType, setTab, setCompanyInfo, setNoOfBedErr } = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [notificationsCount, setNotificationsCount] = useState(10);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
    const [showSidebar, setShowSidebar] = useState(false);
    const [apiError, setApiError] = useState('')
    const [showalert, setShowAlert] = useState(false)

    const navItems = [
        { label: 'Suchen', to: '/search' },
        { label: 'Verkaufen', to: isLoggedIn ? '/dashboard' : '/registrationtypeselection' },
        { label: 'Unternehmen', to: '/businesses' },
        { label: 'Magazin', to: 'https://app2.campervanspot.com/blog' },
        { label: 'Shop', to: '' },
        { label: 'Events', to: '' },
    ];
    const sidebarOptions = [
        { label: 'Dashboard', to: '/dashboard' },
        { label: 'Inserate', to: '/adslist' },
        { label: 'Chats', to: '/chat' },
        { label: 'Suchagenten', to: '/searchAgents' },
        { label: 'Favoriten', to: '/favorites' },
        { label: 'Profil', to: userType !== 'private-user' ? '/businessform' : '/profile' },
    ];


    useEffect(() => {
        if (isFrom !== 'chat') {
            if (token !== '' && token !== null) {
                console.log('token !== null', token)
                callUnreadMessageCount()
                getChatList()
            }
        }
    }, [userData]);


    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };


    const gotoChat = () => {
        if (chatData && chatData.length > 0) {
            navigate('/chat')
        }
    }

    const callLoginapi = async (authToken) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.LOGIN_AD}`;
        console.log('url', url)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.get(url, { headers: { 'Authorization': authToken } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    setUserType(response?.data.user.user_type)
                    if (response?.data.user.user_type === 'private-user') {
                        const data = response?.data.user
                        setUserData(data);
                        setCompanyInfo(response?.data.private_user_profile_information)
                    } else {
                        const data = response?.data.user
                        setUserData(data);
                        setCompanyInfo(response?.data.company_information)
                    }
                    setToken(response?.data?.token)
                    callUnreadMessageCount(response?.data?.token)
                    getChatList(response?.data?.token)
                    setUserType(response?.data.user.user_type)
                    setIsLoggedIn(true)
                }
            })
            .catch(function (error) {

            });
    }


    const validateForm = () => {
        if (!email.trim()) {
            setError(Strings.email_err);
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError(Strings.invalid_email);
            return false;
        }
        return true;
    };



    const loginClick = () => {
        console.log('loginclicked')
        setShowModal(true)
        setCollapsed(true);
    }

    const gotoReg = () => {
        navigate('/registrationtypeselection')
    }

    const closeClick = () => {
        setShowModal(false)
        isClose && isClose()
        // navigate('/home')
    }
    const forgotclick = () => {
        setShowForgotPasswordModal(true)
        closeClick()
    }
    const handleLogout = () => {
        callLogoutapi()
    };

    const handleCloseModal = () => {
        setShowForgotPasswordModal(false);
    };
    const setModalIsOpenToTrue = () => {
        setModalIsOpen(true)
    }

    const setModalIsOpenToFalse = () => {
        setModalIsOpen(false)
    }

    const handlePwdBtnClick = () => {
        if (validateForm()) {
            setShowForgotPasswordModal(false)
            callForgotPasswordApi()
        }
    };

    const getChatList = async () => {
        const url = `${URLS.BASE_URL}${URLS.CHAT_LIST}`;
        await axios.get(url, { headers: { Authorization: token } })
            .then((response) => {
                setLoading(false);
                if (response?.data.status === 1) {
                    if (response?.data.data.length > 0) {
                        setChatData(response?.data.data);
                    }
                } else {
                    console.log('response', response);
                }
            })
            .catch(function (error) {
                console.log('error', error);
                if (error.response) {
                    // Handle error response
                }
            });
    };

    const callUnreadMessageCount = async () => {
        const url = `${URLS.BASE_URL}${URLS.UNREAD_MESSAGE_COUNT}`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                console.log(response)
                setLoading(false)
                if (response?.data.status === 1) {
                    setMessageCount(response.data.data.total_unread_count)
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }




    const callForgotPasswordApi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.FORGOT_PASSWORD}`;
        console.log('url', url)
        const data =
        {
            "email": email,
        }
        console.log('inputdata', data)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.post(url, data)
            .then((response) => {
                setLoading(false)
                console.log('response', response)
                if (response?.data.status === 1) {
                    setModalIsOpenToTrue()
                    setEmail('')
                    setError('')
                }
            })
            .catch(function (error) {
                setLoading(false)
                setError(error.response.data.message)
                console.log('error', error);
            });

    }

    const callLogoutapi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL1}${URLS.LOGOUT}`;
        console.log('url', url)

        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";

        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                console.log(response)
                setLoading(false)
                if (response?.data.status === 1) {
                    setUserData({})
                    setCompanyInfo({})
                    setUserType('')
                    setTab('')
                    setNoOfBedErr('')
                    setMessageCount('')
                    setToken('')
                    setChatData([])
                    setBusinessForm1data({})
                    setBusinessForm2data({})
                    setIsLoggedIn(false)
                    setAddDetails({})
                    navigate('/home')
                } else {
                    setLoading(false)
                    setUserData({})
                    setToken('')
                    setBusinessForm1data({})
                    setBusinessForm2data({})
                    setIsLoggedIn(false)
                    setUserData('')
                    setToken('')
                    setAddDetails({})
                }
            })
            .catch(function (error) {
                setLoading(false)
                setShowAlert(true)
                setApiError(error.response.data.message)
                setUserData({})
                setToken('')
                setBusinessForm1data({})
                setBusinessForm2data({})
                setIsLoggedIn(false)
                setUserData('')
                setToken('')
                setAddDetails({})
                navigate('/home')
                console.log(error);
            });
    }

    return (
        <div>
            {loading && (
                <div className="loader">
                    <ClipLoader color='orange' loading={loading} size={50} />
                </div>
            )}
            <Navbar className='headernavbar' expanded={!collapsed} expand="lg" fixed="top">
                <Navbar.Brand as={Link} to="/" style={{ marginLeft: 20 }}>
                    <img
                        src={require("../../../src/assets/logoheader.webp")}
                        // style={{ maxWidth: 80, maxHeight: 50 }}
                        alt='logo'
                        style={{ width: 80, height: 50 }}
                        onClick={() => navigate('/home')}
                    />
                </Navbar.Brand>
                <Navbar.Toggle
                    onClick={() => setCollapsed(!collapsed)}
                    aria-controls="basic-navbar-nav"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="white"
                        className="bi bi-list"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M1 2.5A.5.5 0 0 1 1.5 2h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-1 0zM1.5 6A.5.5 0 0 1 1 5.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM1 9.5A.5.5 0 0 1 1.5 9h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM1.5 13A.5.5 0 0 1 1 12.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"
                        />
                    </svg>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" style={{ justifyContent: 'space-between', paddingRight: 10 }}>
                    <Nav className="mr-auto">
                        {navItems.map((item, index) => (
                            <Nav.Link
                                disabled={item.label !== 'Unternehmen' && item.label !== 'Magazin' && item.label !== 'Suchen' && item.label !== "Verkaufen"}
                                key={index}
                                as={Link}
                                to={item.to}
                                className={`mx-2 ${location.pathname === item.to ? 'selected' : ''} ${(item.label === 'Unternehmen' || item.label === 'Magazin' || item.label === 'Suchen' || item.label === 'Verkaufen') ? 'text-white' : 'textcolorgray'}`}
                                onClick={() => {
                                    if ((item.label !== 'Unternehmen') || (item.label !== 'Magazin') || (item.label !== 'Suchen') || (item.label !== 'Verkaufen')) {
                                        // If the item is not "Ausbauer", prevent the default click action
                                        // This will prevent navigation to the link
                                        return false;
                                    }
                                }}>{item.label}
                            </Nav.Link>
                        ))}
                    </Nav>
                    {isLoggedIn ? (
                        <Nav>
                            <Button onClick={gotoChat} style={{ backgroundColor: 'rgba(0,0,0,0)', outline: 'none', border: 'none' }}>
                                <div className="notification-container">
                                    <img src={(chatData && chatData.length > 0) ? require('../../assets/bell.png') : require('../../assets/gray-bell.png')} alt='BellIcon' style={{ height: 25, width: 25, }} />
                                    {messageCount > 0 && (
                                        <span className="notification-badge">{messageCount > 9 ? `${9}+` : messageCount}</span>
                                    )}
                                </div>
                            </Button>
                            <Button style={{ color: 'white', textDecoration: 'none' }} variant="link" onClick={toggleSidebar}>
                                <span>Menü</span>
                                <img src={require('../../assets/menu.png')} alt='MenuIcon' style={{ height: 20, width: 20, marginLeft: 10 }} />
                            </Button>
                        </Nav>
                    ) : (
                        <Nav>
                            <div style={{ marginRight: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center' }} className='rowhome'>
                                <div style={{ marginRight: 10 }}>
                                    <Button variant="outline-light" style={{ width: '100%', height: 40 }} className='mb-2 mb-md-0' onClick={loginClick}>{Strings.login}</Button>
                                </div>
                                <div>
                                    <Button variant="outline-light" style={{ width: '100%', height: 40 }} onClick={() => gotoReg()}>{Strings.registration}</Button>
                                </div>
                            </div>
                        </Nav>)}
                </Navbar.Collapse>
            </Navbar>
            {
                isLoggedIn && showSidebar && (
                    <div style={{ width: '250px', position: 'fixed', top: 0, right: 0, height: '100%', backgroundColor: 'rgba(90,90,90,0.9)', zIndex: '1100', padding: 20, }}>
                        <div style={{ marginBottom: 20, flexDirection: 'column' }}>
                            <Button variant="link" onClick={toggleSidebar} style={{ color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {/* <FiArrowLeft style={{ marginRight: '10px' }} /> */}
                                <img src={require('../../assets/backarwhite.png')} style={{ width: 20, height: 20, marginRight: 10 }}></img>
                                Menü
                            </Button>

                            {sidebarOptions.map((option, index) => (
                                <div key={index}>
                                    <Nav.Link as={Link} disabled={(option.label !== 'Profil' && option.label !== 'Inserate' && option.label !== 'Chats' && option.label !== 'Dashboard')} to={option.to} style={{ padding: 10 }}
                                        className={(`${(option.label === 'Profil') || (option.label === 'Inserate') || (option.label === 'Chats' && (chatData && chatData.length > 0)) || (option.label === 'Dashboard') ? 'text-white' : 'textcolorgray'}`)}
                                        onClick={(e) => {
                                            if (option.label === 'Chats') {
                                                if (chatData && chatData.length == 0) {
                                                    e.preventDefault();
                                                }
                                            }
                                        }}
                                    >
                                        {/* <Nav.Link as={Link} disabled={option.label !== 'Profil'} to={option.to} style={{ padding: 10 }}
                                        className={`${(option.label === 'Profil') ? 'text-white' : 'textcolorgray'}`}
                                    > */}
                                        {option.label}
                                    </Nav.Link>
                                    <div className='normaltextLineStyle' />
                                </div>
                            ))}

                            <div style={{ marginTop: '5vmin' }}>
                                <Button variant="outline-light" onClick={handleLogout} >
                                    <img src={require('../../assets/logout.png')} style={{ width: 20, height: 20, marginRight: 5 }}></img>
                                    Abmelden
                                </Button>
                            </div>
                        </div>
                    </div>
                )
            }
            {(showModal || loginClicked) ? <LoginForm onClose={closeClick} onForgotBtnClick={forgotclick} /> : null}
            {
                showForgotPasswordModal &&
                <Modal show={showForgotPasswordModal} onHide={handleCloseModal} style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
                    <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <h4 className='headertext_header'>E-Mail verifizieren</h4>
                            <span className='forgottext' style={{ marginBottom: 20 }}>Bitte gib Deine email-Adresse ein, mit der Du Dich registriert hast.</span>
                            <input
                                type="email"
                                placeholder={Strings.email}
                                value={email}
                                style={{ outline: 'none' }}
                                className='forgot_emailinput'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <btn className="header-next-btn" onClick={handlePwdBtnClick}>Password ändern anfordern!</btn>
                    </Modal.Footer>
                </Modal>
            }

            <Modal show={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                <Modal.Body className='modal-body-popup'>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Button className="btnclose" onClick={setModalIsOpenToFalse}>
                            <img src={require('../../assets/close.png')} style={{ width: 15, height: 15 }}></img>
                        </Button>
                        <p className='text' style={{ textAlign: 'center' }}>Vielen Dank! Wenn diese email-Adresse registriert war, haben wir eine email mit der Anleitung zur Passwort-Änderung an diese versendet. Bitte schau auch in Deinem Spam-Folder nach dieser email.”</p>
                    </div>
                </Modal.Body>
            </Modal>
            {showalert &&
                <AlertComponent message={apiError} onClose={() => { setShowAlert(false) }}></AlertComponent>
            }
        </div >
    );
};

export default Header;
