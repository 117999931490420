import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../utilities/DataContext";

const PrivateRoute = ({ element, redirectTo, requiredRole, message, ...rest }) => {
    const {
        userType, isLoggedIn, setUserData, setCompanyInfo, setUserType, setTab, setNoOfBedErr, setMessageCount, setToken, setChatData, setBusinessForm1data, setBusinessForm2data,
        setIsLoggedIn, setAddDetails, isAdmin
    } = useContext(DataContext);

    const navigate = useNavigate()
    const { pathname, search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const chatId = urlParams.get('chat_id');

    useEffect(() => {
        console.log("isLoggedIn value in PrivateRoute:", isLoggedIn);
        console.log("redirectTo", redirectTo);
        console.log("isAdmin", isAdmin);
        console.log("requiredRole", requiredRole);
        console.log("requiredRoleusertye", userType);
        console.log("message", message);
        if (isLoggedIn === false) {
            setUserData({})
            setCompanyInfo({})
            setUserType('')
            setTab('')
            setNoOfBedErr('')
            setMessageCount('')
            setToken('')
            setChatData([])
            setBusinessForm1data({})
            setBusinessForm2data({})
            setIsLoggedIn(false)
            setAddDetails({})
        }
    }, [isLoggedIn]);

    // Store chatId in session storage if not logged in
    if (!isLoggedIn && chatId) {
        sessionStorage.setItem('chatId', chatId);
    }

    if (!isLoggedIn) {
        return <Navigate to={redirectTo} state={{ message: message }} replace />;
    }

    if (!isLoggedIn && requiredRole && userType !== requiredRole) {
        // Redirect to auth error page if role doesn't match
        return <Navigate to="/auth-error" replace />;
    }
    if (message === 'admin/dashboard' || message === 'admin/instaposts') {
        if (!isAdmin) {
            // Redirect to auth error page if role doesn't match
            return <Navigate to="/auth-error" replace />;
        }
    }
    return element;
};

export default PrivateRoute;