import React from "react"
import { Navbar, Nav, Button, Modal } from 'react-bootstrap';
import '../../app.css'
import { useNavigate, Link } from "react-router-dom";

const AdminHeader = () => {
    const navigate = useNavigate();
    return (
        <Navbar className='headernavbar' fixed="top" style={{ height: 80 }}>
            <Navbar.Brand style={{ marginLeft: 20 }}>
                <img
                    src={require("../../../src/assets/logoheader.webp")}
                    // style={{ maxWidth: 80, maxHeight: 50 }}
                    alt='logo'
                    style={{ width: 80, height: 50 }}
                    onClick={() => navigate('/admin')}
                />
            </Navbar.Brand>
        </Navbar>
    )
}

export default AdminHeader