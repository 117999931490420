import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import AdminDashboard from "./AdminPages/AdminDashboard/AdminDashboard";
import PrivateRoute from "./PrivateRoute";
import AdminInstaList from "./AdminPages/AdminInstaList/AdminInstaList";
import ErrorPage from './ErrorPage/ErrorPage'
import AdminLogin from "./ErrorPage/AdminLogin";


const AdminRoutes = () => {
    console.log('admin path', window.location.pathname)
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin/dashboard" element={<PrivateRoute element={<AdminDashboard />} redirectTo="/admin/login" message='admin/dashboard' />} />
                <Route path="/admin/instaposts" element={<PrivateRoute element={<AdminInstaList />} redirectTo="/admin/login" message='admin/instaposts' />} />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Suspense>
    );
}

export default AdminRoutes;